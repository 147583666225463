import { api } from 'utils/utils-api';

import zonePlacementAdapter from './adapters/zonePlacementAdapter';

export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getZonePlacements = (page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status) {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.device) {
      filterParams += `&filters[device]=${filters.device}`;
    }
    if (filters.ssp) {
      filterParams += `&filters[ssp]=${filters.ssp}`;
    }
    if (filters.zoneId) {
      filterParams += `&filters[zone_id]=${filters.zoneId}`;
    }
    if (filters.country && filters.country.length > 0) {
      filterParams += `&filters[country]=${filters.country.toString()}`;
    }
    if (filters.publishers) {
      filterParams += `&filters[publishers]=${filters.publishers}`;
    }
    if (filters.buycard) {
      filterParams += `&filters[buycard]=${filters.buycard}`;
    }
    if (filters.currency) {
      filterParams += `&filters[currency]=${filters.currency}`;
    }
    if (filters.accepted_placement) {
      filterParams += `&filters[accepted_placement]=${filters.accepted_placement}`;
    }
    if (filters.has_network_only) {
      filterParams += `&filters[has_network_only]=${filters.has_network_only}`;
    }
  }
  const paginated = filters.paginated !== undefined ? filters.paginated : 1;
  return api
    .get(`${apiUrlV3}/zone-placements?&paginated=${paginated}&page=${page}${params}${filterParams}`)
    .then(response => {
      return {
        data: response.data.data.map(zonePlacementAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};
