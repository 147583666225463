import { api } from 'utils/utils-api';

import billingAccountAdapter from './adapters/billingAccountAdapter';

export const billingApiUrl = `${process.env.BILLING_API_URL}/api/v1`;

export const postBillingAccount = data =>
  api
    .post(`${billingApiUrl}/account-billing`, data)
    .then(response => {
      return billingAccountAdapter(response.data.account);
    })
    .catch(error => Promise.reject(error));

export const putBillingAccount = (id, data) =>
  api
    .put(`${billingApiUrl}/account-billing/${id}`, data)
    .then(response => {
      return billingAccountAdapter(response.data.account);
    })
    .catch(error => Promise.reject(error));
