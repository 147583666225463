import { combineReducers } from 'redux';

import adservingsReducer from 'redux/features/adservings/adservingsSlice';
import alertReducer from 'redux/features/alert/alertSlice';
import billingAccountsReducer from 'redux/features/billingAccounts/billingAccountsSlice';
import campaignsReducer from 'redux/features/campaigns/campaignsSlice';
import configsReducer from 'redux/features/configs/configsSlice';
import connectorsReducer from 'redux/features/connectors/connectorsSlice';
import countriesReducer from 'redux/features/countries/countriesSlice';
import creativesReducer from 'redux/features/creatives/creativesSlice';
import deliveryTypesReducer from 'redux/features/deliveryTypes/deliveryTypesSlice';
import dspsReducer from 'redux/features/dsps/dspsSlice';
import externalZonePlacementsReducer from 'redux/features/externalZonePlacements/externalZonePlacementsSlice';
import iabCategoriesReducer from 'redux/features/iabCategories/iabCategoriesSlice';
import interactiveCreativesReducer from 'redux/features/interactiveCreatives/interactiveCreativesSlice';
import leadsReducer from 'redux/features/leads/leadsSlice';
import publishersReducer from 'redux/features/publishers/publishersSlice';
import sitelistsReducer from 'redux/features/sitelists/sitelistsSlice';
import sitesReducer from 'redux/features/sites/sitesSlice';
import usersReducer from 'redux/features/users/usersSlice';
import zoneHostnamesReducer from 'redux/features/zoneHostnames/zoneHostnamesSlice';
import zonePlacementsReducer from 'redux/features/zonePlacements/zonePlacementsSlice';
import zonesReducer from 'redux/features/zones/zonesSlice';

const rootReducer = combineReducers({
  alert: alertReducer,
  configs: configsReducer,
  users: usersReducer,
  campaigns: campaignsReducer,
  creatives: creativesReducer,
  connectors: connectorsReducer,
  adservings: adservingsReducer,
  countries: countriesReducer,
  sitelists: sitelistsReducer,
  sites: sitesReducer,
  zones: zonesReducer,
  zoneHostnames: zoneHostnamesReducer,
  zonePlacements: zonePlacementsReducer,
  externalZonePlacements: externalZonePlacementsReducer,
  dsps: dspsReducer,
  deliveryTypes: deliveryTypesReducer,
  leads: leadsReducer,
  iabCategories: iabCategoriesReducer,
  interactiveCreatives: interactiveCreativesReducer,
  publishers: publishersReducer,
  billingAccounts: billingAccountsReducer,
});

export default rootReducer;
