import { api } from 'utils/utils-api';

export const apiUrl = `${process.env.SBO_API_URL}`;

export const getInteractiveCreatives = (page, search, filters) => {
  const limit = 25;
  const query = `adType=${filters.adType || '*'}&country=${filters.country || '*'}&feature=${
    filters.feature || '*'
  }&vertical=${filters.vertical || '*'}&campaignName=${search || '*'}`;
  const skip = limit * (page - 1);

  return api
    .get(
      `${apiUrl}/api/v1/showcase/interactive-showcases?content_type=product&select=fields,sys.id&limit=${limit}&skip=${skip}&order=-sys.createdAt&${query}`
    )
    .then(response => {
      return {
        meta: {
          per_page: limit,
          current_page: page,
          total: response.data.total,
        },
        data: response.data.data.map(i => ({
          ...i.attributes,
          id: i.id,
        })),
      };
    })
    .catch(error => Promise.reject(error));
};
