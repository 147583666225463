import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RoleProvider } from 'context/RoleProvider';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const LeadsListingContainer = React.lazy(() => import('pages/pubops/LeadsListingContainer'));
const InventoryAdminstrationContainer = React.lazy(() =>
  import('pages/pubops/InventoryAdminstrationContainer')
);
const PublishersListingContainerV2 = React.lazy(() =>
  import('pages/pubops/PublishersListingContainerV2')
);
const SitesListingContainer = React.lazy(() => import('pages/pubops/SitesListingContainer'));
const ZoneFormContainer = React.lazy(() => import('pages/pubops/ZoneFormContainer'));
const SiteEditContainer = React.lazy(() => import('pages/pubops/SiteEditContainer'));
const SiteImportContainer = React.lazy(() => import('pages/pubops/SiteImportContainer'));
const ZonePassbackImportContainer = React.lazy(() =>
  import('pages/pubops/ZonePassbackImportContainer')
);
const OneSkinListingContainer = React.lazy(() => import('pages/pubops/OneSkinListingContainer'));
const ConnectorsGroupsListingContainer = React.lazy(() =>
  import('pages/pubops/ConnectorsGroupsListingContainer')
);
const ConnectorsGroupFormContainer = React.lazy(() =>
  import('pages/pubops/ConnectorsGroupFormContainer')
);
const ConnectorsListingContainer = React.lazy(() =>
  import('pages/pubops/ConnectorsListingContainer')
);
const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));

const PubopsRoutes = () => {
  return (
    <RoleProvider loader={<Loader />} roleSlugs={['internal']}>
      <Routes>
        <Route path="/" element={<Navigate to="/pubops/leads" />} />
        <Route
          path="/leads"
          element={
            <Lazyload loader={<Loader />}>
              <LeadsListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="/publishers"
          element={
            <Lazyload loader={<Loader />}>
              <PublishersListingContainerV2 />
            </Lazyload>
          }
        />
        <Route
          path="/inventory"
          element={
            <Lazyload loader={<Loader />}>
              <InventoryAdminstrationContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites"
          element={
            <Lazyload loader={<Loader />}>
              <SitesListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="/connectors-groups"
          element={
            <Lazyload loader={<Loader />}>
              <ConnectorsGroupsListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="/connectors-groups/:id/edit"
          element={
            <Lazyload loader={<Loader />}>
              <ConnectorsGroupFormContainer />
            </Lazyload>
          }
        />
        <Route
          path="/connectors-groups/:id/connectors"
          element={
            <Lazyload loader={<Loader />}>
              <ConnectorsListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites/add"
          element={
            <Lazyload loader={<Loader />}>
              <SiteEditContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites/import"
          element={
            <Lazyload loader={<Loader />}>
              <SiteImportContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites/:id"
          element={
            <Lazyload loader={<Loader />}>
              <SiteEditContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites/:id/edit"
          element={
            <Lazyload loader={<Loader />}>
              <SiteEditContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites/:siteid/zones/add"
          element={
            <Lazyload loader={<Loader />}>
              <ZoneFormContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sites/:siteid/zones/:id/edit"
          element={
            <Lazyload loader={<Loader />}>
              <ZoneFormContainer />
            </Lazyload>
          }
        />
        <Route
          path="/zones/:id"
          element={
            <Lazyload loader={<Loader />}>
              <ZoneFormContainer />
            </Lazyload>
          }
        />
        <Route
          path="/zones/:id/edit"
          element={
            <Lazyload loader={<Loader />}>
              <ZoneFormContainer />
            </Lazyload>
          }
        />
        <Route
          path="/zones/import"
          element={
            <Lazyload loader={<Loader />}>
              <ZonePassbackImportContainer />
            </Lazyload>
          }
        />
        <Route
          path="/oneskin-sitelist"
          element={
            <Lazyload loader={<Loader />}>
              <OneSkinListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundContainer />
            </Lazyload>
          }
        />
      </Routes>
    </RoleProvider>
  );
};

export default PubopsRoutes;
