import { createSelector, createSlice } from '@reduxjs/toolkit';

import campaignsAdapter from './campaignsAdapter';
import {
  createCampaign,
  duplicateCampaign,
  fetchCampaign,
  fetchCampaigns,
  globalSearchCampaigns,
  updateCampaign,
} from './campaignsAsyncThunk';

const initialState = campaignsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  sample: {
    currentRequestId: undefined,
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const campaignsSlice = createSlice({
  name: 'campaigns',
  initialState,
  reducers: {
    setCampaign(state, action) {
      const campaign = action.payload;
      state.current = {
        status: 'loaded',
        data: campaign,
      };
    },
    clearCampaign(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearCampaigns(state) {
      campaignsAdapter.removeAll(state);
      state.meta = {};
      state.link = {};
    },
    clearCampaignsSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchCampaigns.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          campaignsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchCampaigns.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Get sample
      .addCase(globalSearchCampaigns.pending, (state, action) => {
        state.sample.status = 'loading';
        state.sample.currentRequestId = action.meta.requestId;
        state.sample.data = [];
      })
      .addCase(globalSearchCampaigns.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && requestId === state.sample.currentRequestId) {
          const { data } = action.payload;
          state.sample.status = 'loaded';
          state.sample.data = data;
        }
      })
      .addCase(globalSearchCampaigns.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && state.sample.currentRequestId === requestId) {
          state.sample.status = 'idle';
          state.sample.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchCampaign.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchCampaign.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        campaignsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addCase(fetchCampaign.rejected, state => {
        state.current.status = 'not-found';
      })

      // update single
      .addCase(updateCampaign.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        campaignsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateCampaign.rejected, state => {
        state.current.status = 'idle';
      })

      // create single
      .addCase(createCampaign.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createCampaign.rejected, state => {
        state.current.status = 'idle';
      })

      // duplicate campaign
      .addCase(duplicateCampaign.pending, state => {
        state.current.status = 'duplicating';
      })
      .addCase(duplicateCampaign.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'duplicated';
      })
      .addCase(duplicateCampaign.rejected, state => {
        state.current.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setCampaign, clearCampaign, clearCampaigns, clearCampaignsSample } =
  campaignsSlice.actions;

// Selectors
export const { selectAll: selectCampaigns, selectById: selectCampaignById } =
  campaignsAdapter.getSelectors(state => state.campaigns);

export const selectCampaignIds = createSelector(selectCampaigns, campaigns =>
  campaigns.map(campaign => campaign.id)
);

export const selectCampaignsSample = state => state.campaigns.sample;
export const selectCurrentCampaign = state => state.campaigns.current;
export const selectCurrentCampaignStatus = state => state.campaigns.current.status;
export const selectCampaignsMeta = state => state.campaigns.meta;
export const selectCampaignsFetchStatus = state => state.campaigns.status;

const campaignsReducer = campaignsSlice.reducer;
export default campaignsReducer;
