import { createSlice } from '@reduxjs/toolkit';

import interactiveCreativesAdapter from './interactiveCreativesAdapter';
import { fetchInteractiveCreatives } from './interactiveCreativesAsyncThunk';

const initialState = interactiveCreativesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const interactiveCreativesSlice = createSlice({
  name: 'interactiveCreatives',
  initialState,
  reducers: {
    clearInteractiveCreatives(state) {
      interactiveCreativesAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchInteractiveCreatives.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchInteractiveCreatives.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          interactiveCreativesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchInteractiveCreatives.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { clearInteractiveCreatives } = interactiveCreativesSlice.actions;

// Selectors
export const { selectAll: selectInteractiveCreatives } = interactiveCreativesAdapter.getSelectors(
  state => state.interactiveCreatives
);

export const selectInteractiveCreativesMeta = state => state.interactiveCreatives.meta;
export const selectInteractiveCreativesFetchStatus = state => state.interactiveCreatives.status;

const interactiveCreativesReducer = interactiveCreativesSlice.reducer;
export default interactiveCreativesReducer;
