import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  getSitelistByHash,
  getSitelistChildren,
  getSitelists,
  putSitelist,
} from 'services/sitelist-service';

export const fetchSitelists = createAsyncThunk(
  'sitelists/fetchSitelists',
  withToastForError(async ({ page, search }) => {
    const response = await getSitelists(page, search);
    return response;
  })
);

export const fetchSitelist = createAsyncThunk(
  'sitelists/fetchSitelist',
  withToastForError(async hash => {
    const response = await getSitelistByHash(hash);
    return response;
  })
);

export const fetchWithChildren = createAsyncThunk(
  'sitelists/fetchWithChildren',
  withToastForError(async hash => {
    const response = await getSitelistChildren(hash);
    return response;
  })
);

export const updateSitelist = createAsyncThunk(
  'sitelists/updateSitelist',
  withToastForError(async ({ hash, data }) => {
    const response = await putSitelist(hash, data);
    return response;
  })
);
