import { api } from 'utils/utils-api';

export const apiUrl = `${process.env.SALES_API_URL}/api/v1`;

export const getSitelists = (page, search = '') => {
  const params = search ? `&filters[search]=${search}` : '';
  return api
    .get(
      `${apiUrl}/site-lists?paginated=1&relationships[]=status&per_page=10&filters[parentless]=true&page=${page}${params}`
    )
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getSitelistByHash = hash =>
  api
    .get(`${apiUrl}/site-lists/${hash}`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const getSitelistChildren = hash =>
  api
    .get(`${apiUrl}/site-lists/${hash}?relationships[]=children&relationships[]=status&paginated=0`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));

export const putSitelist = (hash, data) =>
  api
    .put(`${apiUrl}/site-lists/${hash}`, data)
    .then(response => response.data)
    .catch(error => Promise.reject(error));
