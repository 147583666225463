import axios from 'axios';

import { api } from 'utils/utils-api';

import connectorAdapter from './adapters/connectorAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;
export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getConnectors = (
  sasAccessToken,
  page,
  search,
  filters,
  relationships = ['zones', 'campaign']
) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.zoneId) {
      filterParams += `&filters[zone_id]=${filters.zoneId}`;
    }
    if (filters.campaign_id) {
      filterParams += `&filters[campaign_id]=${filters.campaign_id}`;
    }
  }

  const relationshipParams = !filters?.zoneId
    ? `&relationships[]=${relationships.join('&relationships[]=')}`
    : '&relationships[]=improve_sync';

  return axios
    .get(
      `${apiUrl}/connectors?paginated=1&page=${page}${relationshipParams}${params}${filterParams}`,
      {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${sasAccessToken}`,
        },
      }
    )
    .then(response => {
      return {
        data: response.data.data.map(connectorAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};

export const getConnector = (sasAccessToken, id) =>
  axios
    .get(`${apiUrl}/connectors/${id}?relationships[]=campaign&relationships[]=zones`, {
      mode: 'cors',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${sasAccessToken}`,
      },
    })
    .then(response => {
      return connectorAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postConnector = (sasAccessToken, data) =>
  axios
    .post(`${apiUrl}/connectors?relationships[]=campaign&relationships[]=zones`, data, {
      mode: 'cors',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${sasAccessToken}`,
      },
    })
    .then(response => {
      return connectorAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const putConnector = (id, data) =>
  api
    .put(`${apiUrlV3}/connectors/${id}?relationships[]=campaign&relationships[]=zones`, data)
    .then(response => {
      return connectorAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
