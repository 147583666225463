const adservingAdapter = a => {
  if (a.id && a.attributes) {
    return {
      id: a.id,
      ...(a.relationships?.campaign
        ? {
            campaign_name: a.relationships.campaign.attributes.name,
            campaign_enabled: a.relationships.campaign.attributes.enabled,
          }
        : {}),
      ...a.attributes,
      relationships: {
        ...a.relationships,
      },
    };
  }
  return {};
};

export default adservingAdapter;
