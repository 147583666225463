import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RoleProvider } from 'context/RoleProvider';

import AuthorizedRoute from 'routers/utils/AuthorizedRoute';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const CampaignsListingContainer = React.lazy(() =>
  import('pages/demand/CampaignsListingContainer')
);
const CampaignContainer = React.lazy(() => import('pages/demand/CampaignContainer'));
const CampaignFormContainer = React.lazy(() => import('pages/demand/CampaignFormContainer'));
const InteractiveCreativesListingContainer = React.lazy(() =>
  import('pages/demand/InteractiveCreativesListingContainer')
);
const SiteListSspUploadContainer = React.lazy(() =>
  import('pages/demand/SiteListSspUploadContainer')
);
const ZoneExportCommercialContainer = React.lazy(() =>
  import('pages/demand/ZoneExportCommercialContainer')
);
const CampaignPostReportContainer = React.lazy(() =>
  import('pages/demand/CampaignPostReportContainer')
);
const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));

const CreativeRoutes = () => {
  return (
    <RoleProvider
      loader={<Loader />}
      roleSlugs={['creative_manager_restricted', 'creative_manager']}
    >
      <Routes>
        <Route
          path="/campaigns"
          element={
            <Lazyload loader={<Loader />}>
              <CampaignsListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="/campaigns/:id"
          element={
            <Lazyload loader={<Loader />}>
              <CampaignContainer />
            </Lazyload>
          }
        />
        <Route
          path="/campaigns/:id/edit"
          element={
            <Lazyload loader={<Loader />}>
              <CampaignFormContainer />
            </Lazyload>
          }
        />
        <Route element={<AuthorizedRoute roleSlug="creative_manager" />}>
          <Route
            path="/interactive-showcase"
            element={
              <Lazyload loader={<Loader />}>
                <InteractiveCreativesListingContainer />
              </Lazyload>
            }
          />
          <Route
            path="/sitelist-ssp-upload"
            element={
              <Lazyload loader={<Loader />}>
                <SiteListSspUploadContainer />
              </Lazyload>
            }
          />
          <Route
            path="/commercial-export-csv"
            element={
              <Lazyload loader={<Loader />}>
                <ZoneExportCommercialContainer />
              </Lazyload>
            }
          />
          <Route
            path="/post-campaign-report"
            element={
              <Lazyload loader={<Loader />}>
                <CampaignPostReportContainer />
              </Lazyload>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundContainer />
            </Lazyload>
          }
        />
      </Routes>
    </RoleProvider>
  );
};

export default CreativeRoutes;
