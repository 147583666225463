import * as Sentry from '@sentry/browser';
import 'assets/main.scss';
import React from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { render } from 'react-dom';
import 'react-loading-skeleton/dist/skeleton.css';
import { Provider } from 'react-redux';

import store from 'redux/store';

import BoRouter from 'routers/BoRouter';
import MaintenanceRouter from 'routers/MaintenanceRouter';

const maintenanceMode = process.env.MAINTENANCE_MODE;

if (process.env.SENTRY_ENV !== 'local') {
  Sentry.init({
    dsn: process.env.SENTRY_DNS,
    defaultIntegrations: false,
    debug: process.env.SENTRY_ENV !== 'production',
  });
}

const Root = () => {
  return maintenanceMode === 'on' ? (
    <MaintenanceRouter />
  ) : (
    <Provider store={store}>
      <BoRouter />
    </Provider>
  );
};

const node = document.getElementById('root');

render(React.createElement(Root), node);
