import { api } from 'utils/utils-api';

import campaignAdapter from './adapters/campaignAdapter';

export const sasApiUrl = `${process.env.SAS_API_URL}/api/v3`;
export const boApiUrl = `${process.env.SBO_API_URL}/api/v1`;

export const getCampaigns = (page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.traffic_type) {
      filterParams += `&filters[traffic_type]=${filters.traffic_type}`;
    }
    if (filters.period) {
      filterParams += `&filters[period]=${filters.period}`;
    }
    if (filters.country) {
      filterParams += `&filters[country]=${filters.country}`;
    }
    if (filters.lang) {
      filterParams += `&filters[lang]=${filters.lang}`;
    }
    if (filters.year) {
      filterParams += `&filters[year]=${filters.year}`;
    }
    if (filters.ad_id) {
      filterParams += `&filters[ad_id]=${filters.ad_id}`;
    }
    if (filters.ssp_name) {
      filterParams += `&filters[ssp_name]=${filters.ssp_name}`;
    }
    if (filters.owner) {
      filterParams += `&filters[owner]=${filters.owner}`;
    }
    if (filters.type) {
      filterParams += `&filters[type]=${filters.type}`;
    }
    if (filters.creative_type) {
      filterParams += `&filters[creative_type]=${filters.creative_type}`;
    }
    if (filters.creative_source) {
      filterParams += `&filters[creative_source]=${filters.creative_source}`;
    }
    if (filters.ad_template) {
      filterParams += `&filters[ad_template]=${filters.ad_template}`;
    }
    if (filters.advertiser) {
      filterParams += `&filters[advertiser_id]=${filters.advertiser}`;
    }
    if (filters.industry) {
      filterParams += `&filters[industry_id]=${filters.industry}`;
    }
  }

  return api
    .get(
      `${sasApiUrl}/campaigns?paginated=1&per_page=10&page=${page}${params}${filterParams}&relationships[]=advertiser&relationships[]=industry`
    )
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getCampaignsFromLooker = () =>
  api
    .get(`${boApiUrl}/rimbotools/pcr/campaigns`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const getCampaignsInsertionFromLooker = id =>
  api
    .get(`${boApiUrl}/rimbotools/pcr/campaigns/${id}/insertions`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const getCampaign = id =>
  api
    .get(`${sasApiUrl}/campaigns/${id}?relationships[]=advertiser&relationships[]=industry`)
    .then(response => {
      return campaignAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const getCampaignRedirects = (id, creativeId) =>
  api
    .get(
      `${sasApiUrl}/campaigns/${id}/export-redirects${
        creativeId ? `?creative_id=${creativeId}` : ''
      }`
    )
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const getCampaignHistory = id =>
  api
    .get(`${sasApiUrl}/campaigns/${id}/history`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));

export const postCampaign = data =>
  api
    .post(`${sasApiUrl}/campaigns?relationships[]=advertiser&relationships[]=industry`, data)
    .then(response => {
      return campaignAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const putCampaign = (id, data) =>
  api
    .put(`${sasApiUrl}/campaigns/${id}?relationships[]=advertiser&relationships[]=industry`, data)
    .then(response => {
      return campaignAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));

export const postCampaignCopy = id => {
  return api
    .post(`${sasApiUrl}/campaigns/${id}/duplicate`, {})
    .then(response => {
      return campaignAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const postPostCampaignReport = data => {
  return api
    .post(`${boApiUrl}/rimbotools/pcr/generate`, data, {
      extendedHeaders: {
        Accept: 'application/octet-stream',
      },
      extendedOptions: {
        responseType: 'blob',
      },
    })
    .then(response => response.data)
    .catch(error => Promise.reject(error));
};
