import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getAdservings } from 'services/adserving-service';
import { putZoneAdservings } from 'services/zone-service';

export const fetchAdservings = createAsyncThunk(
  'adservings/fetchAdservings',
  withToastForError(async ({ sasAccessToken, page, search, filters }) => {
    const response = await getAdservings(sasAccessToken, page, search, filters);
    return response;
  })
);

export const updateZoneAdservings = createAsyncThunk(
  'adservings/updateZoneAdservings',
  withToastForError(async ({ id, data }) => {
    const response = await putZoneAdservings(id, data);
    return response;
  })
);
