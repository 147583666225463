import { createSelector, createSlice } from '@reduxjs/toolkit';

import { fetchPublisherUsers } from '../users/usersAsyncThunk';
import publishersAdapter from './publishersAdapter';
import {
  createPublisher,
  fetchPublisher,
  fetchPublisherDetailedBillingAccount,
  fetchPublisherTosInformation,
  fetchPublishers,
  updatePublisher,
} from './publishersAsyncThunk';

const initialState = publishersAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    billingStatus: 'idle',
    tosStatus: 'idle',
    usersStatus: 'idle',
    data: {},
  },
  sample: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const publishersSlice = createSlice({
  name: 'publishers',
  initialState,
  reducers: {
    setPublisher(state, action) {
      const publisher = action.payload;
      state.current = {
        status: 'loaded',
        data: publisher,
      };
    },
    clearPublisher(state) {
      state.current = {
        status: 'idle',
        billingStatus: 'idle',
        usersStatus: 'idle',
        data: {},
      };
    },
    clearPublisherUsers(state) {
      state.current.usersStatus = 'idle';
    },
    clearPublishers(state) {
      publishersAdapter.removeAll(state);
      state.meta = {};
      state.link = {};
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchPublishers.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchPublishers.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          publishersAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchPublishers.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchPublisher.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchPublisher.fulfilled, (state, action) => {
        state.current.data = {
          ...state.current.data,
          ...action.payload,
          relationships: {
            ...state.current.data.relationships,
            ...action.payload.relationships,
          },
        };
        state.current.status = 'loaded';
      })
      .addCase(fetchPublisher.rejected, state => {
        state.current.status = 'error';
      })

      // get billing account
      .addCase(fetchPublisherDetailedBillingAccount.pending, state => {
        state.current.billingStatus = 'loading';
      })
      .addCase(fetchPublisherDetailedBillingAccount.fulfilled, (state, action) => {
        state.current.data = {
          ...state.current.data,
          relationships: {
            ...state.current.data.relationships,
            billingAccount: action.payload.current,
          },
        };
        state.current.billingStatus = 'loaded';
      })
      .addCase(fetchPublisherDetailedBillingAccount.rejected, state => {
        state.current.billingStatus = 'error';
      })

      // get tos information
      .addCase(fetchPublisherTosInformation.pending, state => {
        state.current.tosStatus = 'loading';
      })
      .addCase(fetchPublisherTosInformation.fulfilled, (state, action) => {
        state.current.data = {
          ...state.current.data,
          relationships: {
            ...state.current.data.relationships,
            tosInfo: action.payload,
          },
        };
        state.current.tosStatus = 'loaded';
      })
      .addCase(fetchPublisherTosInformation.rejected, state => {
        state.current.tosStatus = 'error';
      })

      // get user list
      .addCase(fetchPublisherUsers.pending, state => {
        state.current.usersStatus = 'loading';
      })
      .addCase(fetchPublisherUsers.fulfilled, state => {
        state.current.usersStatus = 'loaded';
      })
      .addCase(fetchPublisherUsers.rejected, state => {
        state.current.usersStatus = 'error';
      })

      // create single
      .addCase(createPublisher.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createPublisher.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createPublisher.rejected, state => {
        state.current.status = 'idle';
      })

      // update single
      .addCase(updatePublisher.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updatePublisher.fulfilled, (state, action) => {
        state.current.status = 'updated';
        state.current.data = action.payload;
      })
      .addCase(updatePublisher.rejected, state => {
        state.current.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setPublisher, clearPublisher, clearPublishers } = publishersSlice.actions;

// Selectors
export const { selectAll: selectPublishers, selectById: selectPublisherById } =
  publishersAdapter.getSelectors(state => state.publishers);
export const selectPublisherIds = createSelector(selectPublishers, publishers =>
  publishers.map(i => i.id)
);

export const selectPublishersRequestId = state => state.publishers.currentRequestId;
export const selectSamplePublisher = state => state.publishers.sample;
export const selectCurrentPublisher = state => state.publishers.current;
export const selectPublishersMeta = state => state.publishers.meta;
export const selectPublishersFetchStatus = state => state.publishers.status;

const publishersReducer = publishersSlice.reducer;
export default publishersReducer;
