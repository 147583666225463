import { createSelector, createSlice } from '@reduxjs/toolkit';

import dspsAdapter from './dspsAdapter';
import { fetchDsps } from './dspsAsyncThunk';

const initialState = dspsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
});

/* eslint-disable no-param-reassign */
const dspsSlice = createSlice({
  name: 'dsps',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchDsps.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchDsps.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          dspsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchDsps.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Selectors
export const { selectAll: selectDsps, selectById: selectDspById } = dspsAdapter.getSelectors(
  state => state.dsps
);

export const selectDspIds = createSelector(selectDsps, dsps => dsps.map(d => d.id));

export const selectDspsFetchStatus = state => state.dsps.status;

const dspsReducer = dspsSlice.reducer;
export default dspsReducer;
