import { createSelector, createSlice } from '@reduxjs/toolkit';

import iabCategoriesAdapter from './iabCategoriesAdapter';
import { fetchIabCategories } from './iabCategoriesAsyncThunk';

const initialState = iabCategoriesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
});

/* eslint-disable no-param-reassign */
const iabCategoriesSlice = createSlice({
  name: 'iabCategories',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchIabCategories.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchIabCategories.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          iabCategoriesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchIabCategories.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Selectors
export const { selectAll: selectIabCategories, selectById: selectIabCategoryById } =
  iabCategoriesAdapter.getSelectors(state => state.iabCategories);

export const selectIabCategoryIds = createSelector(selectIabCategories, iabCategories =>
  iabCategories.map(i => i.id)
);

export const selectIabCategoriesFetchStatus = state => state.iabCategories.status;

const iabCategoriesReducer = iabCategoriesSlice.reducer;
export default iabCategoriesReducer;
