const zonePlacementAdapter = zp => {
  if (zp.id && zp.attributes) {
    return {
      id: zp.id,
      ...zp.attributes,
      network:
        zp.attributes.network_id !== 0 && zp.attributes.network_name
          ? `${zp.attributes.network_id} - ${zp.attributes.network_name}`
          : '',
    };
  }
  return {};
};

export default zonePlacementAdapter;
