import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getExternalZonePlacements } from 'services/externalZonePlacement-service';
import {
  getSingleExternalZonePlacement,
  postExternalZonePlacement,
  postExternalZonePlacements,
  putExternalZonePlacement,
} from 'services/zone-service';

export const fetchExternalZonePlacements = createAsyncThunk(
  'externalZonePlacements/fetchExternalZonePlacements',
  withToastForError(async ({ sasAccessToken, page, search, filters }) => {
    const response = await getExternalZonePlacements(sasAccessToken, page, search, filters);
    return response;
  })
);

export const fetchExternalZonePlacement = createAsyncThunk(
  'externalZonePlacements/fetchExternalZonePlacement',
  withToastForError(async ({ sasAccessToken, id }) => {
    const response = await getSingleExternalZonePlacement(sasAccessToken, id);
    return response;
  })
);

export const updateExternalZonePlacement = createAsyncThunk(
  'externalZonePlacements/updateExternalZonePlacement',
  withToastForError(async ({ id, data }) => {
    const response = await putExternalZonePlacement(id, data);
    return response;
  })
);

export const createExternalZonePlacement = createAsyncThunk(
  'externalZonePlacements/createExternalZonePlacement',
  withToastForError(async ({ data }) => {
    const response = await postExternalZonePlacement(data);
    return response;
  })
);

export const createExternalZonePlacements = createAsyncThunk(
  'externalZonePlacements/createExternalZonePlacements',
  withToastForError(async ({ data }) => {
    const response = await postExternalZonePlacements(data);
    return response;
  })
);
