import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from 'context/AuthProvider';

import Loader from 'components/atoms/Loader';

const AuthorizedRoute = ({ roleSlug, redirectPath }) => {
  const { authUser, checkRole } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthorized, setIsAuthorized] = useState(null);

  const check = async value => {
    const hasRole = await checkRole(value);
    setIsAuthorized(hasRole);
  };

  useEffect(() => {
    if (authUser && isLoading) {
      check(roleSlug);
    }
  }, [authUser]);

  useEffect(() => {
    if (isLoading && authUser && isAuthorized !== null) {
      setIsLoading(false);
    }
  }, [isAuthorized]);

  return isLoading ? (
    <Loader />
  ) : isAuthorized ? (
    <Outlet />
  ) : (
    <Navigate to={redirectPath || '/not-authorized'} replace />
  );
};

AuthorizedRoute.propTypes = {
  roleSlug: PropTypes.oneOf([
    'internal',
    'publisher',
    'admin',
    'creative_manager',
    'creative_manager_restricted',
  ]).isRequired,
  redirectPath: PropTypes.string,
};

AuthorizedRoute.defaultProps = {
  redirectPath: '/not-authorized',
};

export default AuthorizedRoute;
