import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

import { AuthProvider } from 'context/AuthProvider';

import AdminRoutes from 'routers/routes/AdminRoutes';
import BrandsRoutes from 'routers/routes/BrandsRoutes';
import CreativeRoutes from 'routers/routes/CreativeRoutes';
import PubopsRoutes from 'routers/routes/PubopsRoutes';
import SalesRoutes from 'routers/routes/SalesRoutes';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const LoginContainer = React.lazy(() => import('pages/auth/LoginContainer'));
const ResetPasswordContainer = React.lazy(() => import('pages/auth/ResetPasswordContainer'));
const ForgotPasswordContainer = React.lazy(() => import('pages/auth/ForgotPasswordContainer'));
const HomeContainer = React.lazy(() => import('pages/HomeContainer'));
const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));
const NotAuthorizedContainer = React.lazy(() => import('pages/NotAuthorizedContainer'));

const BoRouter = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/login"
          element={
            <Lazyload loader={<Loader />}>
              <LoginContainer type="rich-media" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/forgot-password"
          element={
            <Lazyload loader={<Loader />}>
              <ForgotPasswordContainer type="rich-media" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/reset-password"
          element={
            <Lazyload loader={<Loader />}>
              <ResetPasswordContainer type="rich-media" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/update-password"
          element={
            <Lazyload loader={<Loader />}>
              <ResetPasswordContainer forceNew type="rich-media" />
            </Lazyload>
          }
          exact
        />
        <Route
          path="/"
          element={
            <AuthProvider loader={<Loader />}>
              <Routes>
                <Route
                  path="/"
                  element={
                    <Lazyload loader={<Loader />}>
                      <HomeContainer />
                    </Lazyload>
                  }
                />
              </Routes>
            </AuthProvider>
          }
          exact
        />
        <Route
          path="/*"
          element={
            <AuthProvider loader={<Loader />}>
              <Routes>
                <Route path="/admin/*" element={<AdminRoutes />} />
                <Route path="/brands/*" element={<BrandsRoutes />} />
                <Route path="/pubops/*" element={<PubopsRoutes />} />
                <Route path="/sales/*" element={<SalesRoutes />} />
                <Route
                  path="/not-authorized"
                  element={
                    <Lazyload loader={<Loader />}>
                      <NotAuthorizedContainer />
                    </Lazyload>
                  }
                />

                <Route path="/*" element={<CreativeRoutes />} />
                <Route
                  path="*"
                  element={
                    <Lazyload loader={<Loader />}>
                      <NotFoundContainer />
                    </Lazyload>
                  }
                />
              </Routes>
            </AuthProvider>
          }
        />
      </Routes>
    </Router>
  );
};

export default BoRouter;
