import { createSelector, createSlice } from '@reduxjs/toolkit';

import creativesAdapter from './creativesAdapter';
import {
  checkCreativeTagWeight,
  createCreative,
  createCreativeRedirect,
  deleteAsset,
  duplicateCreative,
  fetchCreative,
  fetchCreativeRedirects,
  fetchCreativeTag,
  fetchCreativeZones,
  fetchCreatives,
  globalSearchCreatives,
  updateCreative,
  uploadCreativeGwdAsset,
  uploadCreativeIabAsset,
  uploadCreativeImage,
  uploadCreativeMedia,
} from './creativesAsyncThunk';

const initialState = creativesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  sample: {
    currentRequestId: undefined,
    status: 'idle',
    data: [],
  },
  deleteAsset: {
    status: 'idle',
    data: {},
  },
  checkAsset: {
    status: 'idle',
    message: null,
    data: {},
  },
  creativeAssets: {
    status: 'idle',
    message: null,
    data: {
      tag: '',
    },
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const creativesSlice = createSlice({
  name: 'creatives',
  initialState,
  reducers: {
    setCreative(state, action) {
      const creative = action.payload;
      state.current = {
        status: 'loaded',
        data: creative,
      };
    },
    clearCreative(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
      state.checkAsset = {
        status: 'idle',
        message: '',
        data: {},
      };
      state.creativeAssets = {
        status: 'idle',
        message: null,
        data: {
          tag: '',
        },
      };
    },
    clearCreatives(state) {
      creativesAdapter.removeAll(state);
    },
    clearCreativesSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
    clearCheckAsset(state) {
      state.checkAsset = {
        status: 'idle',
        message: '',
        data: {},
      };
    },
    clearCreativeAssets(state) {
      state.creativeAssets = {
        status: 'idle',
        message: null,
        data: {
          tag: '',
        },
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi Creatives
      .addCase(fetchCreatives.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchCreatives.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          creativesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchCreatives.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Get sample
      .addCase(globalSearchCreatives.pending, (state, action) => {
        state.sample.status = 'loading';
        state.sample.currentRequestId = action.meta.requestId;
        state.sample.data = [];
      })
      .addCase(globalSearchCreatives.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && requestId === state.sample.currentRequestId) {
          const { data } = action.payload;
          state.sample.status = 'loaded';
          state.sample.data = data;
        }
      })
      .addCase(globalSearchCreatives.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && state.sample.currentRequestId === requestId) {
          state.sample.status = 'idle';
          state.sample.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchCreative.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchCreative.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        creativesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addCase(fetchCreative.rejected, state => {
        state.current.status = 'error';
      })

      // update single
      .addCase(updateCreative.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateCreative.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        creativesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateCreative.rejected, state => {
        state.current.status = 'idle';
      })

      // upload creative GWD asset
      .addCase(uploadCreativeGwdAsset.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(uploadCreativeGwdAsset.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        creativesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(uploadCreativeGwdAsset.rejected, state => {
        state.current.status = 'idle';
      })

      // upload creative IAB asset
      .addCase(uploadCreativeIabAsset.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(uploadCreativeIabAsset.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        creativesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(uploadCreativeIabAsset.rejected, state => {
        state.current.status = 'idle';
      })

      // upload creative Image
      .addCase(uploadCreativeImage.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(uploadCreativeImage.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        creativesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(uploadCreativeImage.rejected, state => {
        state.current.status = 'idle';
      })

      // upload creative media
      .addCase(uploadCreativeMedia.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(uploadCreativeMedia.fulfilled, (state, action) => {
        const { data } = action.meta.arg;
        let uploadedData = {};
        if (data.video) {
          uploadedData = {
            relationships: {
              ...state.current.data.relationships,
              video: action.payload,
            },
            vast_url: '',
          };
        }
        if (data.video_top) {
          uploadedData = {
            relationships: {
              ...state.current.data.relationships,
              video_top: action.payload,
            },
            vast_url: '',
          };
        }
        if (data.video_bg) {
          uploadedData = {
            relationships: {
              ...state.current.data.relationships,
              video_bg: action.payload,
            },
            video_bg_option: 'different',
          };
        }

        state.current.data = {
          ...state.current.data,
          ...uploadedData,
        };
        state.current.status = 'updated';
      })
      .addCase(uploadCreativeMedia.rejected, state => {
        state.current.status = 'idle';
      })

      // create single
      .addCase(createCreative.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createCreative.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createCreative.rejected, state => {
        state.current.status = 'idle';
      })

      // get creative zones
      .addCase(fetchCreativeZones.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchCreativeZones.fulfilled, (state, action) => {
        state.current.data.relationships = {
          ...state.current.data.relationships,
          zones: action.payload.data,
        };
        state.current.status = 'loaded';
      })
      .addCase(fetchCreativeZones.rejected, state => {
        state.current.status = 'idle';
      })

      // get creative redirects
      .addCase(fetchCreativeRedirects.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchCreativeRedirects.fulfilled, (state, action) => {
        state.current.data.relationships = {
          ...state.current.data.relationships,
          redirects: action.payload,
        };
        state.current.status = 'loaded';
      })
      .addCase(fetchCreativeRedirects.rejected, state => {
        state.current.status = 'idle';
      })

      // create creative redirect
      .addCase(createCreativeRedirect.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(createCreativeRedirect.fulfilled, (state, action) => {
        state.current.data.status = 'published';
        state.current.data.relationships = {
          ...state.current.data.relationships,
          redirects: action.payload,
        };
        state.current.status = 'updated';
      })
      .addCase(createCreativeRedirect.rejected, state => {
        state.current.status = 'idle';
      })

      // duplicate creative
      .addCase(duplicateCreative.pending, state => {
        state.current.status = 'duplicating';
      })
      .addCase(duplicateCreative.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'duplicated';
      })
      .addCase(duplicateCreative.rejected, state => {
        state.current.status = 'idle';
      })

      // delete asset creative
      .addCase(deleteAsset.pending, state => {
        state.deleteAsset.status = 'deleting';
      })
      .addCase(deleteAsset.fulfilled, (state, action) => {
        state.deleteAsset.data = action.payload;
        state.deleteAsset.status = 'deleted';
      })
      .addCase(deleteAsset.rejected, state => {
        state.deleteAsset.status = 'idle';
      })

      // check creative asset
      .addCase(checkCreativeTagWeight.pending, state => {
        state.checkAsset.status = 'checking';
        state.checkAsset.message = '';
      })
      .addCase(checkCreativeTagWeight.fulfilled, (state, action) => {
        state.checkAsset.data = action.payload;
        state.checkAsset.status = 'checked';
      })
      .addCase(checkCreativeTagWeight.rejected, (state, action) => {
        const { error } = action;
        state.checkAsset.status = 'error';
        state.checkAsset.message = error?.message || 'celtra redirect is invalid!';
      })

      // fetch creative tag
      .addCase(fetchCreativeTag.pending, state => {
        state.creativeAssets.status = 'loading';
        state.creativeAssets.data.tag = '';
        state.creativeAssets.message = '';
      })
      .addCase(fetchCreativeTag.fulfilled, (state, action) => {
        state.creativeAssets.data.tag = action.payload;
        state.creativeAssets.status = 'loaded';
      })
      .addCase(fetchCreativeTag.rejected, (state, action) => {
        const { error } = action;
        state.creativeAssets.status = 'error';
        state.creativeAssets.message =
          error?.message || 'An error occured while fetching celtra redirect!';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const {
  setCreative,
  clearCreative,
  clearCreatives,
  clearCreativesSample,
  clearCheckAsset,
  clearCreativeAssets,
} = creativesSlice.actions;

// Selectors
export const { selectAll: selectCreatives, selectById: selectCreativeById } =
  creativesAdapter.getSelectors(state => state.creatives);

export const selectCreativeIds = createSelector(selectCreatives, creatives =>
  creatives.map(creative => creative.id)
);

export const selectCreativesSample = state => state.creatives.sample;
export const selectCurrentCreative = state => state.creatives.current;
export const selectCurrentCreativeCampaign = state =>
  state.creatives.current.data.relationships?.campaign || null;
export const selectCreativesMeta = state => state.creatives.meta;
export const selectCreativesFetchStatus = state => state.creatives.status;
export const selectDeleteAsset = state => state.creatives.deleteAsset;
export const selectCheckAsset = state => state.creatives.checkAsset;
export const selectCreativeAssets = state => state.creatives.creativeAssets;

const creativesReducer = creativesSlice.reducer;
export default creativesReducer;
