import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RoleProvider } from 'context/RoleProvider';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const BrandsContainer = React.lazy(() => import('pages/BrandsContainer'));
const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));

const BrandsRoutes = () => {
  return (
    <RoleProvider loader={<Loader />} roleSlugs={['internal']}>
      <Routes>
        <Route path="/" element={<Navigate to="/brands/imported-brands" />} />
        <Route
          path="/imported-brands"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="imported-brands/" />
            </Lazyload>
          }
        />
        <Route
          path="/imported-brands/:id"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="imported-brands/" />
            </Lazyload>
          }
        />
        <Route
          path="/sublime-brands"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="brands/" />
            </Lazyload>
          }
        />
        <Route
          path="/sublime-brands/:id"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="brands/" />
            </Lazyload>
          }
        />
        <Route
          path="/advertisers"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="advertisers/" />
            </Lazyload>
          }
        />
        <Route
          path="/advertisers/:id"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="advertisers/" />
            </Lazyload>
          }
        />
        <Route
          path="/categories"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="categories/" />
            </Lazyload>
          }
        />
        <Route
          path="/categories/:id"
          element={
            <Lazyload loader={<Loader />}>
              <BrandsContainer path="categories/" />
            </Lazyload>
          }
        />
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundContainer />
            </Lazyload>
          }
        />
      </Routes>
    </RoleProvider>
  );
};

export default BrandsRoutes;
