import { createSelector, createSlice } from '@reduxjs/toolkit';

import deliveryTypesAdapter from './deliveryTypesAdapter';
import { fetchDeliveryTypes } from './deliveryTypesAsyncThunk';

const initialState = deliveryTypesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
});

/* eslint-disable no-param-reassign */
const deliveryTypesSlice = createSlice({
  name: 'deliveryTypes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchDeliveryTypes.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchDeliveryTypes.fulfilled, (state, action) => {
        const { data } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          deliveryTypesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchDeliveryTypes.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Selectors
export const { selectAll: selectDeliveryTypes, selectById: selectCountryById } =
  deliveryTypesAdapter.getSelectors(state => state.deliveryTypes);

export const selectCountryIds = createSelector(selectDeliveryTypes, deliveryTypes =>
  deliveryTypes.map(d => d.id)
);

export const selectDeliveryTypesFetchStatus = state => state.deliveryTypes.status;

const deliveryTypesReducer = deliveryTypesSlice.reducer;
export default deliveryTypesReducer;
