import React from 'react';
import { Route, Routes } from 'react-router-dom';

import { RoleProvider } from 'context/RoleProvider';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const SalesContainer = React.lazy(() => import('pages/SalesContainer'));
const SitelistsListingContainer = React.lazy(() => import('pages/sales/SitelistsListingContainer'));
const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));

const SalesRoutes = () => {
  return (
    <RoleProvider loader={<Loader />} roleSlugs={['internal']}>
      <Routes>
        <Route
          path="/sitelists"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/generate/"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/generate/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/generate-international/"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/generate-international" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/generate/:hash"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/generate/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/moderation/:hash"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/moderation/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/moderation/:hash/:id"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/moderation/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/validation/:hash"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/validation/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/validation/:hash/:id"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/validation/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/final/:hash"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/final/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/final/:hash/:id"
          element={
            <Lazyload loader={<Loader />}>
              <SalesContainer path="/admin/final/" />
            </Lazyload>
          }
        />
        <Route
          path="/sitelists/all"
          element={
            <Lazyload loader={<Loader />}>
              <SitelistsListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundContainer />
            </Lazyload>
          }
        />
      </Routes>
    </RoleProvider>
  );
};

export default SalesRoutes;
