import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getZonePlacements } from 'services/zonePlacement-service';

export const fetchZonePlacements = createAsyncThunk(
  'zonePlacements/fetchZonePlacements',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getZonePlacements(page, search, filters);
    return response;
  })
);
