const ImprovePublisherAdapter = ipd => {
  if (ipd.id && ipd.attributes) {
    return {
      id: ipd.attributes.publisher_id,
      name: ipd.attributes.publisher_name,
    };
  }
  return {};
};

export default ImprovePublisherAdapter;
