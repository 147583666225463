import axios from 'axios';

import { api } from 'utils/utils-api';

import ImprovePublisherAdapter from './adapters/ImprovePublisherAdapter';
import externalZonePlacementAdapter from './adapters/externalZonePlacementAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;
export const apiUrlV3 = `${process.env.SAS_API_URL}/api/v3`;

export const getExternalZonePlacements = (sasAccessToken, page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status) {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.device) {
      filterParams += `&filters[device]=${filters.device}`;
    }
    if (filters.ssp) {
      filterParams += `&filters[ssp]=${filters.ssp}`;
    }
    if (filters.zoneId) {
      filterParams += `&filters[zone_id]=${filters.zoneId}`;
    }
    if (filters.country) {
      filterParams += `&filters[country]=${filters.country.toString()}`;
    }
    if (filters.publishers) {
      filterParams += `&filters[publishers]=${filters.publishers}`;
    }
  }
  const paginated = filters?.paginated !== undefined ? filters.paginated : 1;

  return axios
    .get(
      `${apiUrl}/external-zone-placements?relationships[]=zones&relationships[]=sites&relationships[]=improve_placement_data&paginated=${paginated}&page=${page}${params}${filterParams}`,
      {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${sasAccessToken}`,
        },
      }
    )
    .then(response => {
      return {
        data: response.data.data.map(externalZonePlacementAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};

export const getPublishers = search => {
  const params = search ? `?filters[search]=${search}` : '';
  return api
    .get(`${apiUrlV3}/improve/publishers${params}`)
    .then(response => {
      return {
        data: response.data.data.map(ImprovePublisherAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};
