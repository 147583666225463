import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  deleteAssetCreative,
  getCreative,
  getCreativeCeltraTag,
  getCreativeHistory,
  getCreativeRedirects,
  getCreatives,
  postCreative,
  postCreativeCopy,
  postCreativeGwd,
  postCreativeIab,
  postCreativeImage,
  postCreativeMedia,
  postCreativeRedirect,
  postCreativeTagWeight,
  putCreative,
} from 'services/creative-service';
import { getZones } from 'services/zone-service';

export const fetchCreatives = createAsyncThunk(
  'creatives/fetchCreatives',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getCreatives(page, search, filters);
    return response;
  })
);

export const globalSearchCreatives = createAsyncThunk(
  'creatives/globalSearchCreatives',
  withToastForError(async ({ page, search }) => {
    const response = await getCreatives(page, search);
    return response;
  })
);

export const fetchCreative = createAsyncThunk(
  'creatives/fetchCreative',
  withToastForError(async ({ id, filters }) => {
    const response = await getCreative(id, filters);
    return response;
  })
);

export const createCreative = createAsyncThunk(
  'creatives/createCreative',
  withToastForError(async ({ data }) => {
    const response = await postCreative(data);
    return response;
  })
);

export const updateCreative = createAsyncThunk(
  'creatives/updateCreative',
  withToastForError(async ({ id, data }) => {
    const response = await putCreative(id, data);
    return response;
  })
);

export const uploadCreativeMedia = createAsyncThunk(
  'creatives/uploadCreativeMedia',
  withToastForError(async ({ id, data }) => {
    const response = await postCreativeMedia(id, data);
    return response;
  })
);

export const uploadCreativeImage = createAsyncThunk(
  'creatives/uploadCreativeImage',
  withToastForError(async ({ id, data }) => {
    const response = await postCreativeImage(id, data);
    return response;
  })
);

export const uploadCreativeGwdAsset = createAsyncThunk(
  'creatives/uploadCreativeGwdAsset',
  withToastForError(async ({ id, data }) => {
    const response = await postCreativeGwd(id, data);
    return response;
  })
);

export const uploadCreativeIabAsset = createAsyncThunk(
  'creatives/uploadCreativeIabAsset',
  withToastForError(async ({ id, data }) => {
    const response = await postCreativeIab(id, data);
    return response;
  })
);

export const fetchCreativeRedirects = createAsyncThunk(
  'creatives/fetchCreativeRedirects',
  withToastForError(async ({ id }) => {
    const response = await getCreativeRedirects(id);
    return response;
  })
);

export const createCreativeRedirect = createAsyncThunk(
  'creatives/createCreativeRedirect',
  withToastForError(async ({ id, data }) => {
    await postCreativeRedirect(id, data);
    const redirects = await getCreativeRedirects(id);
    return redirects;
  })
);

export const duplicateCreative = createAsyncThunk(
  'creatives/duplicateCreative',
  withToastForError(async ({ id, data }) => {
    const response = await postCreativeCopy(id, data);
    return response;
  })
);

export const fetchCreativeZones = createAsyncThunk(
  'creatives/fetchCreativeZones',
  withToastForError(async ({ id }) => {
    const response = await getZones(1, '', { ad_id: id }, { sites: true });
    return response;
  })
);

export const fetchCreativeHistory = createAsyncThunk(
  'creatives/fetchCreativeHistory',
  withToastForError(async ({ id }) => {
    const response = await getCreativeHistory(id);
    return response;
  })
);

export const deleteAsset = createAsyncThunk(
  'creatives/deleteAsset',
  withToastForError(async ({ creativeId, assetName }) => {
    const response = await deleteAssetCreative(creativeId, assetName);
    return response;
  })
);

export const checkCreativeTagWeight = createAsyncThunk(
  'creatives/checkCreativeTagWeight',
  withToastForError(async ({ tag, type }) => {
    const response = await postCreativeTagWeight(tag, type);
    return response;
  })
);

export const fetchCreativeTag = createAsyncThunk(
  'creatives/fetchCreativeTag',
  withToastForError(async ({ celtraCreativeId }) => {
    const response = await getCreativeCeltraTag(celtraCreativeId);
    return response;
  })
);
