import { createSelector, createSlice } from '@reduxjs/toolkit';

import { fetchPublisherDetailedBillingAccount } from '../publishers/publishersAsyncThunk';
import billingAccountsAdapter from './billingAccountsAdapter';
import { createBillingAccount, updateBillingAccount } from './billingAccountsAsyncThunk';

const initialState = billingAccountsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  sample: {
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const billingAccountsSlice = createSlice({
  name: 'billingAccounts',
  initialState,
  reducers: {
    setBillingAccount(state, action) {
      const billingAccount = action.payload;
      state.current = {
        status: 'loaded',
        data: billingAccount,
      };
    },
    clearBillingAccount(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearBillingAccounts(state) {
      billingAccountsAdapter.removeAll(state);
    },
    clearBillingAccountsSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder

      // Get multi
      .addCase(fetchPublisherDetailedBillingAccount.pending, (state, action) => {
        state.status = 'loading';
        state.current.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchPublisherDetailedBillingAccount.fulfilled, (state, action) => {
        const { archived, current, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          billingAccountsAdapter.setAll(state, archived);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }

        if (state.current.status === 'loading') {
          state.current.status = 'loaded';
          state.current.data = current;
        }
      })
      .addCase(fetchPublisherDetailedBillingAccount.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }

        if (state.current.status === 'loading') {
          state.current.status = 'idle';
          state.current.data = {};
        }
      })

      // Create single
      .addCase(createBillingAccount.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createBillingAccount.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createBillingAccount.rejected, state => {
        state.current.status = 'idle';
      })

      // Update single
      .addCase(updateBillingAccount.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateBillingAccount.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        billingAccountsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateBillingAccount.rejected, state => {
        state.current.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const {
  setBillingAccount,
  clearBillingAccount,
  clearBillingAccounts,
  clearBillingAccountsSample,
} = billingAccountsSlice.actions;

// Selectors
export const { selectAll: selectBillingAccounts, selectById: selectBillingAccountById } =
  billingAccountsAdapter.getSelectors(state => state.billingAccounts);

export const selectBillingAccountIds = createSelector(selectBillingAccounts, billingAccounts =>
  billingAccounts.map(billingAccount => billingAccount.id)
);

export const selectCurrentBillingAccount = state => state.billingAccounts.current;
export const selectBillingAccountsMeta = state => state.billingAccounts.meta;
export const selectBillingAccountsFetchStatus = state => state.billingAccounts.status;

const billingAccountsReducer = billingAccountsSlice.reducer;
export default billingAccountsReducer;
