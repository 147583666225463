import { createSelector, createSlice } from '@reduxjs/toolkit';

import zoneHostnamesAdapter from './zoneHostnamesAdapter';
import {
  createZoneHostnames,
  fetchHostnames,
  fetchSingleZoneHostname,
  fetchZoneHostnames,
  updateZoneHostnames,
} from './zoneHostnamesAsyncThunk';

const initialState = zoneHostnamesAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  createMulti: {
    status: 'idle',
    errors: null,
  },
  current: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const zoneHostnamesSlice = createSlice({
  name: 'zoneHostnames',
  initialState,
  reducers: {
    setZoneHostname(state, action) {
      const zoneHostname = action.payload;
      state.current = {
        status: 'loaded',
        data: zoneHostname,
      };
    },
    clearZoneHostname(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearZoneHostnameMulti(state) {
      state.createMulti = {
        status: 'idle',
        errors: null,
      };
    },
    clearZoneHostnames(state) {
      zoneHostnamesAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      // Get multi by zone
      .addCase(fetchZoneHostnames.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchZoneHostnames.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          zoneHostnamesAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchZoneHostnames.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Get multi
      .addCase(fetchHostnames.pending, state => {
        state.status = 'loading';
      })
      .addCase(fetchHostnames.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        zoneHostnamesAdapter.setAll(state, data);
        state.status = 'loaded';
        state.meta = meta || {};
        state.links = links || {};
      })
      .addCase(fetchHostnames.rejected, state => {
        state.status = 'idle';
      })

      // get single
      .addCase(fetchSingleZoneHostname.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchSingleZoneHostname.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        zoneHostnamesAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addCase(fetchSingleZoneHostname.rejected, state => {
        state.current.status = 'error';
      })

      // update single
      .addCase(updateZoneHostnames.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateZoneHostnames.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload.data,
        };
        zoneHostnamesAdapter.updateOne(state, update);
        state.current.data = action.payload;

        if (state.current.data.status === 'archived') {
          state.current.status = 'archived';
        } else {
          state.current.status = 'updated';
        }
      })
      .addCase(updateZoneHostnames.rejected, state => {
        state.current.status = 'idle';
      })

      // create multi
      .addCase(createZoneHostnames.pending, state => {
        state.createMulti.status = 'creating';
        state.createMulti.errors = null;
      })
      .addCase(createZoneHostnames.fulfilled, (state, action) => {
        state.createMulti.status = 'created';
        if (action.payload.errors.length) {
          state.createMulti.errors = action.payload.errors;
        }
      })
      .addCase(createZoneHostnames.rejected, state => {
        state.createMulti.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setZoneHostname, clearZoneHostname, clearZoneHostnames, clearZoneHostnameMulti } =
  zoneHostnamesSlice.actions;

// Selectors
export const { selectAll: selectZoneHostnames, selectById: selectZoneHostnameById } =
  zoneHostnamesAdapter.getSelectors(state => state.zoneHostnames);

export const selectZoneHostnameIds = createSelector(selectZoneHostnames, zoneHostnames =>
  zoneHostnames.map(zoneHostname => zoneHostname.id)
);

export const selectZoneHostnamesDesktop = createSelector(selectZoneHostnames, zoneHostnames =>
  zoneHostnames.filter(hostname => hostname.device === 'desktop' && hostname.status !== 'archived')
);

export const selectZoneHostnamesMobile = createSelector(selectZoneHostnames, zoneHostnames =>
  zoneHostnames.filter(hostname => hostname.device === 'mobile' && hostname.status !== 'archived')
);

export const selectZoneHostnamesTablet = createSelector(selectZoneHostnames, zoneHostnames =>
  zoneHostnames.filter(hostname => hostname.device === 'tablet' && hostname.status !== 'archived')
);

export const selectCurrentZoneHostname = state => state.zoneHostnames.current;
export const selectZoneHostnamesMeta = state => state.zoneHostnames.meta;
export const selectZoneHostnamesFetchStatus = state => state.zoneHostnames.status;
export const selectZoneHostnamesCreateMultiStatus = state => state.zoneHostnames.createMulti.status;
export const selectZoneHostnamesCreateMultiErrors = state => state.zoneHostnames.createMulti.errors;

const zoneHostnamesReducer = zoneHostnamesSlice.reducer;
export default zoneHostnamesReducer;
