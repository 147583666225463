import _ from 'lodash';

const creativeAdapter = c => {
  if (c.id && c.attributes) {
    return {
      id: c.id,
      ...c.attributes,
      creative_assets_path: c.attributes.creative_assets_path
        ? `${process.env.RICHMEDIA_CREATIVE_CDN_URL}/${c.attributes.creative_assets_path}`
        : null,
      video_autoplay: parseInt(c.attributes.video_autoplay, 10) === 1,
      fixed: parseInt(c.attributes.fixed, 10) === 1,
      top: parseInt(c.attributes.top, 10),
      desktop_setup: !_.isEmpty(c.attributes.desktop_setup)
        ? {
            ...c.attributes.desktop_setup,
            reminder: parseInt(c.attributes.desktop_setup.reminder, 10) === 1,
          }
        : {},
      mobile_setup: !_.isEmpty(c.attributes.mobile_setup)
        ? {
            ...c.attributes.mobile_setup,
            top: {
              ...c.attributes.mobile_setup.top,
              tooltipType: parseInt(c.attributes.mobile_setup.top.tooltipType, 10),
              autoHideTopDuration: parseInt(c.attributes.mobile_setup.top.autoHideTopDuration, 10),
              showPanelWhenClicked:
                parseInt(c.attributes.mobile_setup.top.showPanelWhenClicked, 10) === 1,
              supportTopClose: parseInt(c.attributes.mobile_setup.top.supportTopClose, 10) === 1,
            },
            auto_pause: !_.isUndefined(c.attributes.mobile_setup.auto_pause)
              ? parseInt(c.attributes.mobile_setup.auto_pause, 10) === 1
              : true,
            toproll: {
              ...c.attributes.mobile_setup.toproll,
              closeDelay: c.attributes.mobile_setup.toproll?.closeDelay || null,
            },
          }
        : {},
      relationships: {
        ...c.relationships,
      },
    };
  }
  return {};
};

export default creativeAdapter;
