import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  getCampaign,
  getCampaignHistory,
  getCampaigns,
  postCampaign,
  postCampaignCopy,
  putCampaign,
} from 'services/campaign-service';

export const globalSearchCampaigns = createAsyncThunk(
  'campaigns/globalSearchCampaigns',
  withToastForError(async ({ page, search }) => {
    const response = await getCampaigns(page, search);
    return response;
  })
);

export const fetchCampaigns = createAsyncThunk(
  'campaigns/fetchCampaigns',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getCampaigns(page, search, filters);
    return response;
  })
);

export const fetchCampaign = createAsyncThunk(
  'campaigns/fetchCampaign',
  withToastForError(async ({ id }) => {
    const response = await getCampaign(id);
    return response;
  })
);

export const fetchCampaignHistory = createAsyncThunk(
  'campaigns/fetchCampaignHistory',
  withToastForError(async ({ id }) => {
    const response = await getCampaignHistory(id);
    return response;
  })
);

export const createCampaign = createAsyncThunk(
  'campaigns/createCampaign',
  withToastForError(async ({ data }) => {
    const response = await postCampaign(data);
    return response;
  })
);

export const updateCampaign = createAsyncThunk(
  'campaigns/updateCampaign',
  withToastForError(async ({ id, data }) => {
    const response = await putCampaign(id, data);
    return response;
  })
);

export const duplicateCampaign = createAsyncThunk(
  'campaigns/duplicateCampaign',
  withToastForError(async ({ id }) => {
    const response = await postCampaignCopy(id);
    return response;
  })
);
