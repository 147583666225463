import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getIabCategories } from 'services/iabCategory-service';

export const fetchIabCategories = createAsyncThunk(
  'iabCategories/fetchIabCategories',
  withToastForError(async ({ sasAccessToken, page, search, filters }) => {
    const response = await getIabCategories(sasAccessToken, page, search, filters);
    return response;
  })
);
