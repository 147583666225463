import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { RoleProvider } from 'context/RoleProvider';

import Lazyload from 'utils/Lazyload';

import Loader from 'components/atoms/Loader';

const InvoicesContainer = React.lazy(() => import('pages/admin/InvoicesContainer'));
const UserListingContainer = React.lazy(() => import('pages/admin/UserListingContainer'));
const NotFoundContainer = React.lazy(() => import('pages/NotFoundContainer'));

const AdminRoutes = () => {
  return (
    <RoleProvider loader={<Loader />} roleSlugs={['admin']}>
      <Routes>
        <Route path="/" element={<Navigate to="/admin/accounts" />} />
        <Route
          path="/accounts"
          element={
            <Lazyload loader={<Loader />}>
              <UserListingContainer />
            </Lazyload>
          }
        />
        <Route
          path="/invoices"
          element={
            <Lazyload loader={<Loader />}>
              <InvoicesContainer />
            </Lazyload>
          }
        />
        <Route
          path="*"
          element={
            <Lazyload loader={<Loader />}>
              <NotFoundContainer />
            </Lazyload>
          }
        />
      </Routes>
    </RoleProvider>
  );
};

export default AdminRoutes;
