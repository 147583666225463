import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getDsps } from 'services/dsp-service';

export const fetchDsps = createAsyncThunk(
  'dsps/fetchDsps',
  withToastForError(async () => {
    const response = await getDsps();
    return response;
  })
);
