import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  getConnector,
  getConnectors,
  postConnector,
  putConnector,
} from 'services/connector-service';
import { putZoneConnectors } from 'services/zone-service';

export const fetchConnectors = createAsyncThunk(
  'connectors/fetchConnectors',
  withToastForError(async ({ sasAccessToken, page, search, filters }) => {
    const response = await getConnectors(sasAccessToken, page, search, filters);
    return response;
  })
);

export const globalSearchConnectors = createAsyncThunk(
  'connectors/globalSearchConnectors',
  withToastForError(async ({ sasAccessToken, page, search, filters }) => {
    const response = await getConnectors(sasAccessToken, page, search, filters, ['campaign']);
    return response;
  })
);

export const fetchConnector = createAsyncThunk(
  'connectors/fetchConnector',
  withToastForError(async ({ sasAccessToken, id }) => {
    const response = await getConnector(sasAccessToken, id);
    return response;
  })
);

export const createConnector = createAsyncThunk(
  'connectors/createConnector',
  withToastForError(async ({ sasAccessToken, data }) => {
    const response = await postConnector(sasAccessToken, data);
    return response;
  })
);

export const updateConnector = createAsyncThunk(
  'connectors/updateConnector',
  withToastForError(async ({ id, data }) => {
    const response = await putConnector(id, data);
    return response;
  })
);

export const updateZoneConnectors = createAsyncThunk(
  'connectors/updateZoneConnectors',
  withToastForError(async ({ id, data }) => {
    const response = await putZoneConnectors(id, data);
    return response;
  })
);
