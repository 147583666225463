import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getHostnames } from 'services/hostname-service';
import {
  getSingleZoneHostname,
  getZoneHostnames,
  postZoneHostnames,
  putZoneHostnames,
} from 'services/zone-service';

export const fetchHostnames = createAsyncThunk(
  'zoneHostnames/fetchHostnames',
  withToastForError(async ({ sasAccessToken, page, search, filters }) => {
    const response = await getHostnames(sasAccessToken, page, search, filters);
    return response;
  })
);

export const fetchZoneHostnames = createAsyncThunk(
  'zoneHostnames/fetchZoneHostnames',
  withToastForError(async ({ sasAccessToken, id, page, search, filters }) => {
    const response = await getZoneHostnames(sasAccessToken, id, page, search, filters);
    return response;
  })
);

export const fetchSingleZoneHostname = createAsyncThunk(
  'zoneHostnames/fetchZoneHostname',
  withToastForError(async ({ sasAccessToken, id }) => {
    const response = await getSingleZoneHostname(sasAccessToken, id);
    return response;
  })
);

export const updateZoneHostnames = createAsyncThunk(
  'zoneHostnames/updateZoneHostnames',
  withToastForError(async ({ id, data }) => {
    const response = await putZoneHostnames(id, data);
    return response;
  })
);

export const createZoneHostnames = createAsyncThunk(
  'zoneHostnames/postZoneHostnames',
  withToastForError(async ({ data }) => {
    const response = await postZoneHostnames(data);
    return response;
  })
);
