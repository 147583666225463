import { createSelector, createSlice } from '@reduxjs/toolkit';

import adservingsAdapter from './adservingsAdapter';
import { fetchAdservings, updateZoneAdservings } from './adservingsAsyncThunk';

const initialState = adservingsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  sample: {
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const adservingsSlice = createSlice({
  name: 'adservings',
  initialState,
  reducers: {
    setAdserving(state, action) {
      const adserving = action.payload;
      state.current = {
        status: 'loaded',
        data: adserving,
      };
    },
    clearAdserving(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearAdservings(state) {
      adservingsAdapter.removeAll(state);
    },
    clearAdservingsSample(state) {
      state.sample = {
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchAdservings.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchAdservings.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          adservingsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchAdservings.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // update multi
      .addCase(updateZoneAdservings.pending, state => {
        state.status = 'updating';
      })
      .addCase(updateZoneAdservings.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        adservingsAdapter.setAll(state, data);
        state.status = 'updated';
        state.meta = meta || {};
        state.links = links || {};
      })
      .addCase(updateZoneAdservings.rejected, state => {
        state.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setAdserving, clearAdserving, clearAdservings, clearAdservingsSample } =
  adservingsSlice.actions;

// Selectors
export const { selectAll: selectAdservings, selectById: selectAdservingById } =
  adservingsAdapter.getSelectors(state => state.adservings);

export const selectAdservingIds = createSelector(selectAdservings, adservings =>
  adservings.map(adserving => adserving.id)
);

export const selectAdservingsMobile = createSelector(selectAdservings, adservings =>
  adservings.filter(adserving => adserving.supported_devices === 'mobile')
);

export const selectAdservingsDesktop = createSelector(selectAdservings, adservings =>
  adservings.filter(adserving => adserving.supported_devices === 'desktop')
);

export const selectCurrentAdserving = state => state.adservings.current;
export const selectAdservingsMeta = state => state.adservings.meta;
export const selectAdservingsFetchStatus = state => state.adservings.status;

const adservingsReducer = adservingsSlice.reducer;
export default adservingsReducer;
