import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getDeliveryTypes } from 'services/deliveryType-service';

export const fetchDeliveryTypes = createAsyncThunk(
  'deliveryTypes/fetchDeliveryTypes',
  withToastForError(async () => {
    const response = await getDeliveryTypes();
    return response;
  })
);
