import axios from 'axios';

import adservingAdapter from './adapters/adservingAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;

export const getAdservings = (sasAccessToken, page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.zoneId) {
      filterParams += `&filters[zone_id]=${filters.zoneId}`;
    }
    if (filters.campaign_id) {
      filterParams += `&filters[campaign_id]=${filters.campaign_id}`;
    }
    if (filters.device) {
      filterParams += `&filters[device]=${filters.device}`;
    }
    if (filters.campaign_status) {
      filterParams += `&filters[campaign_status]=${filters.campaign_status}`;
    }
    if (filters.campaign_name) {
      filterParams += `&filters[campaign_name]=${filters.campaign_name}`;
    }
  }

  return axios
    .get(
      `${apiUrl}/adservings?paginated=1&page=${page}&relationships[]=zones&relationships[]=campaign${params}${filterParams}`,
      {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${sasAccessToken}`,
        },
      }
    )
    .then(response => {
      return {
        data: response.data.data.map(adservingAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};
