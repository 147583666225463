import axios from 'axios';

import iabCategoryAdapter from './adapters/iabCategoryAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;

export const getIabCategories = (sasAccessToken, page, search, filters) => {
  const pagination = page ? `paginated=1&page=${page}` : 'paginated=0';
  const params = search ? `&filters[names]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.levels) {
      filterParams += `&filters[levels]=${filters.levels}`;
    }
  }

  return axios
    .get(`${apiUrl}/iab-categories?${pagination}${params}${filterParams}`, {
      mode: 'cors',
      headers: {
        Accept: 'application/vnd.api+json',
        Authorization: `Bearer ${sasAccessToken}`,
      },
    })
    .then(response => {
      return {
        data: response.data.data.map(iabCategoryAdapter),
      };
    })
    .catch(error => Promise.reject(error));
};
