import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { getInteractiveCreatives } from 'services/interactiveCreative-service';

export const fetchInteractiveCreatives = createAsyncThunk(
  'interactiveCreative/fetchInteractiveCreatives',
  withToastForError(async ({ page, search, filters }) => {
    const response = await getInteractiveCreatives(page, search, filters);
    return response;
  })
);
