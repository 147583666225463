import axios from 'axios';

import zoneHostnameAdapter from './adapters/zoneHostnameAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v1`;

export const getHostnames = (sasAccessToken, page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.status) {
      filterParams += `&filters[status]=${filters.status}`;
    }
    if (filters.device) {
      filterParams += `&filters[device]=${filters.device}`;
    }
    if (filters.country) {
      filterParams += `&filters[country]=${filters.country.toString()}`;
    }
    if (filters.zoneId) {
      filterParams += `&filters[zone_id]=${filters.zoneId}`;
    }
    if (filters.accepted_placement) {
      filterParams += `&filters[accepted_placement]=${filters.accepted_placement}`;
    }
  }
  const paginated = filters.paginated !== undefined ? filters.paginated : 1;

  return axios
    .get(
      `${apiUrl}/hostnames?relationships[]=zones&relationships[]=sites&paginated=${paginated}&page=${page}${params}${filterParams}`,
      {
        mode: 'cors',
        headers: {
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${sasAccessToken}`,
        },
      }
    )
    .then(response => {
      return {
        data: response.data.data.map(zoneHostnameAdapter),
        meta: response.data.meta,
      };
    })
    .catch(error => Promise.reject(error));
};
