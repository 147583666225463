import { api } from 'utils/utils-api';

import creativeAdapter from './adapters/creativeAdapter';

export const apiUrl = `${process.env.SAS_API_URL}/api/v3`;
export const sboApiUrl = `${process.env.SBO_API_URL}/api/v1`;

export const getCreatives = (page, search, filters) => {
  const params = search ? `&filters[search]=${search}` : '';

  let filterParams = '';
  if (filters) {
    if (filters.creative_type) {
      filterParams += `&filters[creative_type]=${filters.creative_type}`;
    }
    if (filters.type) {
      filterParams += `&filters[type]=${filters.type}`;
    }
    if (filters.campaign_id) {
      filterParams += `&filters[campaign_id]=${filters.campaign_id}`;
    }
    if (filters.ad_template) {
      filterParams += `&filters[ad_template]=${filters.ad_template}`;
    }
    if (filters.creative_source) {
      filterParams += `&filters[creative_source]=${filters.creative_source}`;
    }
    if (!filters.show_archived) {
      filterParams += `&filters[status]=draft,published`;
    }
  }

  return api
    .get(`${apiUrl}/creatives?paginated=1&per_page=10&page=${page}${params}${filterParams}`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getCreative = (id, filters) => {
  let filterParams = '';
  if (filters) {
    if (filters.campaign_id) {
      filterParams += `&filters[campaign_id]=${filters.campaign_id}`;
    }
  }

  return api
    .get(
      `${apiUrl}/creatives/${id}?relationships[]=campaign&relationships[]=zones&relationships[]=media_videos&relationships[]=gwd&relationships[]=iab${filterParams}`
    )
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const postCreative = data => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return api
    .post(
      `${apiUrl}/creatives?relationships[]=campaign&relationships[]=zones&relationships[]=media_videos&relationships[]=gwd&relationships[]=iab`,
      formData,
      {
        extendedHeaders: {
          'Content-type': 'multipart/form-data',
        },
      }
    )
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const putCreative = (id, data) => {
  return api
    .put(
      `${apiUrl}/creatives/${id}?relationships[]=campaign&relationships[]=zones&relationships[]=media_videos&relationships[]=gwd&relationships[]=iab`,
      data
    )
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeMedia = (id, data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return api
    .post(`${apiUrl}/creatives/${id}/media`, formData, {
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeImage = (id, data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));
  return api
    .post(
      `${apiUrl}/creatives/${id}/image?relationships[]=campaign&relationships[]=zones&relationships[]=media_videos`,
      formData,
      {
        extendedHeaders: {
          'Content-type': 'multipart/form-data',
        },
      }
    )
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeGwd = (id, data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return api
    .post(
      `${apiUrl}/creatives/${id}/gwd?relationships[]=campaign&relationships[]=zones&relationships[]=gwd`,
      formData,
      {
        extendedHeaders: {
          'Content-type': 'multipart/form-data',
        },
      }
    )
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeIab = (id, data) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  return api
    .post(`${apiUrl}/creatives/${id}/iab?relationships[]=iab`, formData, {
      extendedHeaders: {
        'Content-type': 'multipart/form-data',
      },
    })
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const getCreativeRedirects = id => {
  return api
    .get(`${apiUrl}/creatives/${id}/redirects`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeRedirect = (id, data) => {
  return api
    .post(`${apiUrl}/creatives/${id}/redirects`, data)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeCopy = (id, data) => {
  return api
    .post(
      `${apiUrl}/creatives/${id}/duplicate?relationships[]=campaign&relationships[]=zones`,
      data
    )
    .then(response => {
      return creativeAdapter(response.data.data);
    })
    .catch(error => Promise.reject(error));
};

export const getCreativeHistory = id => {
  return api
    .get(`${apiUrl}/creatives/${id}/history`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const deleteAssetCreative = (creativeId, assetName) => {
  return api
    .delete(`${apiUrl}/creatives/${creativeId}/media/${assetName}`)
    .then(response => {
      return response.data.data;
    })
    .catch(error => Promise.reject(error));
};

export const postCreativeTagWeight = (tag, type) => {
  return api
    .post(`${sboApiUrl}/rimbotools/tag/check-weight`, { tag, type })
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};

export const getCreativeCeltraTag = celtraCreativeId => {
  return api
    .get(`${sboApiUrl}/rimbotools/tag/${celtraCreativeId}`)
    .then(response => {
      return response.data;
    })
    .catch(error => Promise.reject(error));
};
