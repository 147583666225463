import { createSelector, createSlice } from '@reduxjs/toolkit';

import zonePlacementsAdapter from './zonePlacementsAdapter';
import { fetchZonePlacements } from './zonePlacementsAsyncThunk';

const initialState = zonePlacementsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const zonePlacementsSlice = createSlice({
  name: 'zonePlacements',
  initialState,
  reducers: {
    setZonePlacement(state, action) {
      const zonePlacement = action.payload;
      state.current = {
        status: 'loaded',
        data: zonePlacement,
      };
    },
    clearZonePlacement(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearZonePlacements(state) {
      zonePlacementsAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchZonePlacements.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchZonePlacements.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          zonePlacementsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchZonePlacements.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setZonePlacement, clearZonePlacement, clearZonePlacements } =
  zonePlacementsSlice.actions;

// Selectors
export const { selectAll: selectZonePlacements, selectById: selectZonePlacementById } =
  zonePlacementsAdapter.getSelectors(state => state.zonePlacements);

export const selectZonePlacementIds = createSelector(selectZonePlacements, zonePlacements =>
  zonePlacements.map(zonePlacement => zonePlacement.id)
);

export const selectZonePlacementsDesktop = createSelector(selectZonePlacements, zonePlacements =>
  zonePlacements.filter(zonePlacement => zonePlacement.device === 'desktop')
);

export const selectZonePlacementsMobile = createSelector(selectZonePlacements, zonePlacements =>
  zonePlacements.filter(zonePlacement => zonePlacement.device === 'mobile')
);

export const selectZonePlacementsTablet = createSelector(selectZonePlacements, zonePlacements =>
  zonePlacements.filter(zonePlacement => zonePlacement.device === 'tablet')
);

export const selectCurrentZonePlacement = state => state.zonePlacements.current;
export const selectZonePlacementsMeta = state => state.zonePlacements.meta;
export const selectZonePlacementsFetchStatus = state => state.zonePlacements.status;

const zonePlacementsReducer = zonePlacementsSlice.reducer;
export default zonePlacementsReducer;
