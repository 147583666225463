import { api } from 'utils/utils-api';

export const apiUrl = `${process.env.SAS_API_URL}/api/v3`;

export const getDeliveryTypes = () => {
  return api
    .get(`${apiUrl}/delivery-types`)
    .then(response => {
      return response;
    })
    .catch(error => Promise.reject(error));
};
