import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import { postBillingAccount, putBillingAccount } from 'services/billingAccount-service';

export const createBillingAccount = createAsyncThunk(
  'billingAccount/createBillingAccount',
  withToastForError(async ({ data }) => {
    const response = await postBillingAccount(data);
    return response;
  })
);

export const updateBillingAccount = createAsyncThunk(
  'billingAccount/updateBillingAccount',
  withToastForError(async ({ id, data }) => {
    const response = await putBillingAccount(id, data);
    return response;
  })
);
