import { createAsyncThunk } from '@reduxjs/toolkit';

import { withToastForError } from 'redux/sliceTools';

import {
  getAllPublishers,
  getPublisher,
  getPublisherBillingAccount,
  getPublisherDetailedBillingAccount,
  getTosInformation,
  postPublisher,
  putPublisher,
} from 'services/publisher-service';

export const fetchPublishers = createAsyncThunk(
  'publishers/fetchPublishers',
  withToastForError(async ({ page, search, filters, sort }) => {
    const response = await getAllPublishers(page, search, filters, sort);
    return response;
  })
);

export const fetchPublisher = createAsyncThunk(
  'publishers/fetchPublisher',
  withToastForError(async ({ id }) => {
    const response = await getPublisher(id);
    return response;
  })
);

export const fetchPublisherBillingAccount = createAsyncThunk(
  'publishers/fetchPublisherBillingAccount',
  withToastForError(async ({ id }) => {
    const response = await getPublisherBillingAccount(id);
    return response;
  })
);

export const fetchPublisherDetailedBillingAccount = createAsyncThunk(
  'publishers/fetchPublisherDetailedBillingAccount',
  withToastForError(async ({ id }) => {
    const response = await getPublisherDetailedBillingAccount(id);
    return response;
  })
);

export const fetchPublisherTosInformation = createAsyncThunk(
  'publishers/fetchPublisherTosInformation',
  withToastForError(async ({ id }) => {
    const response = await getTosInformation(id);
    return response;
  })
);

export const updatePublisher = createAsyncThunk(
  'publishers/updatePublisher',
  withToastForError(async ({ id, data }) => {
    const response = await putPublisher(id, data);
    return response;
  })
);

export const createPublisher = createAsyncThunk(
  'publishers/createPublisher',
  withToastForError(async data => {
    const response = await postPublisher(data);
    return response;
  })
);
