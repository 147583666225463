import { createSelector, createSlice } from '@reduxjs/toolkit';

import sitelistsAdapter from './sitelistsAdapter';
import { fetchSitelists, fetchWithChildren, updateSitelist } from './sitelistsAsyncThunk';

const initialState = sitelistsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const sitelistsSlice = createSlice({
  name: 'sitelists',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchSitelists.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchSitelists.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          sitelistsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchSitelists.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      .addCase(fetchWithChildren.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchWithChildren.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.current.status = 'loaded';
        state.current.data = data;
      })
      .addCase(fetchWithChildren.rejected, state => {
        state.current.status = 'idle';
      })

      .addCase(updateSitelist.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        sitelistsAdapter.updateOne(state, update);
      });
  },
});
/* eslint-enable no-param-reassign */

// Selectors
export const { selectAll: selectSitelists, selectById: selectSitelistById } =
  sitelistsAdapter.getSelectors(state => state.sitelists);

export const selectSitelistIds = createSelector(selectSitelists, sitelists =>
  sitelists.map(sitelist => sitelist.id)
);

export const selectSitelistsMeta = state => state.sitelists.meta;
export const selectSitelistsFetchStatus = state => state.sitelists.status;
export const selectCurrentSitelist = state => state.sitelists.current;

const sitelistsReducer = sitelistsSlice.reducer;
export default sitelistsReducer;
