import { createSelector, createSlice } from '@reduxjs/toolkit';

import externalZonePlacementsAdapter from './externalZonePlacementsAdapter';
import {
  createExternalZonePlacement,
  createExternalZonePlacements,
  fetchExternalZonePlacement,
  fetchExternalZonePlacements,
  updateExternalZonePlacement,
} from './externalZonePlacementsAsyncThunk';

const initialState = externalZonePlacementsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  createMulti: {
    status: 'idle',
    errors: null,
  },
  current: {
    status: 'idle',
    data: {},
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const externalZonePlacementsSlice = createSlice({
  name: 'externalZonePlacements',
  initialState,
  reducers: {
    setExternalZonePlacement(state, action) {
      const externalZonePlacement = action.payload;
      state.current = {
        status: 'loaded',
        data: externalZonePlacement,
      };
    },
    clearExternalZonePlacement(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearExternalZonePlacementMulti(state) {
      state.createMulti = {
        status: 'idle',
        errors: null,
      };
    },
    clearExternalZonePlacements(state) {
      externalZonePlacementsAdapter.removeAll(state);
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchExternalZonePlacements.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchExternalZonePlacements.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          externalZonePlacementsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchExternalZonePlacements.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // get single
      .addCase(fetchExternalZonePlacement.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchExternalZonePlacement.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        externalZonePlacementsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addCase(fetchExternalZonePlacement.rejected, state => {
        state.current.status = 'error';
      })

      // update single
      .addCase(updateExternalZonePlacement.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateExternalZonePlacement.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload.data,
        };
        externalZonePlacementsAdapter.updateOne(state, update);
        state.current.data = action.payload;

        if (state.current.data.status === 'archived') {
          state.current.status = 'archived';
        } else {
          state.current.status = 'updated';
        }
      })
      .addCase(updateExternalZonePlacement.rejected, state => {
        state.current.status = 'idle';
      })

      // create one
      .addCase(createExternalZonePlacement.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(createExternalZonePlacement.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createExternalZonePlacement.rejected, state => {
        state.current.status = 'idle';
      })

      // create multi
      .addCase(createExternalZonePlacements.pending, state => {
        state.createMulti.status = 'creating';
        state.createMulti.errors = null;
      })
      .addCase(createExternalZonePlacements.fulfilled, (state, action) => {
        state.createMulti.status = 'created';
        if (action.payload.errors.length) {
          state.createMulti.errors = action.payload.errors;
        }
      })
      .addCase(createExternalZonePlacements.rejected, state => {
        state.createMulti.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const {
  setExternalZonePlacement,
  clearExternalZonePlacement,
  clearExternalZonePlacements,
  clearExternalZonePlacementMulti,
} = externalZonePlacementsSlice.actions;

// Selectors
export const {
  selectAll: selectExternalZonePlacements,
  selectById: selectExternalZonePlacementById,
} = externalZonePlacementsAdapter.getSelectors(state => state.externalZonePlacements);

export const selectExternalZonePlacementIds = createSelector(
  selectExternalZonePlacements,
  externalZonePlacements =>
    externalZonePlacements.map(externalZonePlacement => externalZonePlacement.id)
);

export const selectExternalZonePlacementsDesktop = createSelector(
  selectExternalZonePlacements,
  externalZonePlacements =>
    externalZonePlacements.filter(
      externalPlacement =>
        externalPlacement.device === 'desktop' && externalPlacement.status !== 'archived'
    )
);

export const selectExternalZonePlacementsMobile = createSelector(
  selectExternalZonePlacements,
  externalZonePlacements =>
    externalZonePlacements.filter(
      externalPlacement =>
        externalPlacement.device === 'mobile' && externalPlacement.status !== 'archived'
    )
);

export const selectExternalZonePlacementsTablet = createSelector(
  selectExternalZonePlacements,
  externalZonePlacements =>
    externalZonePlacements.filter(
      externalPlacement =>
        externalPlacement.device === 'tablet' && externalPlacement.status !== 'archived'
    )
);

export const selectCurrentExternalZonePlacement = state => state.externalZonePlacements.current;
export const selectExternalZonePlacementsMeta = state => state.externalZonePlacements.meta;
export const selectExternalZonePlacementsFetchStatus = state => state.externalZonePlacements.status;
export const selectExternalZonePlacementsCreateMultiStatus = state =>
  state.externalZonePlacements.createMulti.status;
export const selectExternalZonePlacementsCreateMultiErrors = state =>
  state.externalZonePlacements.createMulti.errors;

const externalZonePlacementsReducer = externalZonePlacementsSlice.reducer;
export default externalZonePlacementsReducer;
