import { createSelector, createSlice } from '@reduxjs/toolkit';

import connectorsAdapter from './connectorsAdapter';
import {
  createConnector,
  fetchConnector,
  fetchConnectors,
  globalSearchConnectors,
  updateConnector,
  updateZoneConnectors,
} from './connectorsAsyncThunk';

const initialState = connectorsAdapter.getInitialState({
  currentRequestId: undefined,
  status: 'idle',
  current: {
    status: 'idle',
    data: {},
  },
  sample: {
    currentRequestId: undefined,
    status: 'idle',
    data: [],
  },
  meta: {},
  links: {},
});

/* eslint-disable no-param-reassign */
const connectorsSlice = createSlice({
  name: 'connectors',
  initialState,
  reducers: {
    setConnector(state, action) {
      const connector = action.payload;
      state.current = {
        status: 'loaded',
        data: connector,
      };
    },
    clearConnector(state) {
      state.current = {
        status: 'idle',
        data: {},
      };
    },
    clearConnectors(state) {
      connectorsAdapter.removeAll(state);
    },
    clearConnectorsSample(state) {
      state.sample = {
        currentRequestId: undefined,
        status: 'idle',
        data: {},
      };
    },
  },
  extraReducers: builder => {
    builder
      // Get multi
      .addCase(fetchConnectors.pending, (state, action) => {
        state.status = 'loading';
        state.currentRequestId = action.meta.requestId;
      })
      .addCase(fetchConnectors.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        const { requestId } = action.meta;
        if (state.status === 'loading' && requestId === state.currentRequestId) {
          connectorsAdapter.setAll(state, data);
          state.status = 'loaded';
          state.meta = meta || {};
          state.links = links || {};
          state.currentRequestId = undefined;
        }
      })
      .addCase(fetchConnectors.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.status === 'loading' && state.currentRequestId === requestId) {
          state.status = 'idle';
          state.currentRequestId = undefined;
        }
      })

      // Get sample
      .addCase(globalSearchConnectors.pending, (state, action) => {
        state.sample.status = 'loading';
        state.sample.currentRequestId = action.meta.requestId;
        state.sample.data = [];
      })
      .addCase(globalSearchConnectors.fulfilled, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && requestId === state.sample.currentRequestId) {
          const { data } = action.payload;
          state.sample.status = 'loaded';
          state.sample.data = data;
        }
      })
      .addCase(globalSearchConnectors.rejected, (state, action) => {
        const { requestId } = action.meta;
        if (state.sample.status === 'loading' && state.sample.currentRequestId === requestId) {
          state.sample.status = 'idle';
          state.sample.currentRequestId = undefined;
        }
      })

      // update multi
      .addCase(updateZoneConnectors.pending, state => {
        state.status = 'updating';
      })
      .addCase(updateZoneConnectors.fulfilled, (state, action) => {
        const { data, links, meta } = action.payload;
        connectorsAdapter.setAll(state, data);
        state.status = 'updated';
        state.meta = meta || {};
        state.links = links || {};
      })
      .addCase(updateZoneConnectors.rejected, state => {
        state.status = 'idle';
      })

      // Get single
      .addCase(fetchConnector.pending, state => {
        state.current.status = 'loading';
      })
      .addCase(fetchConnector.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        connectorsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'loaded';
      })
      .addCase(fetchConnector.rejected, state => {
        state.current.status = 'not-found';
      })

      // Update single
      .addCase(updateConnector.pending, state => {
        state.current.status = 'updating';
      })
      .addCase(updateConnector.fulfilled, (state, action) => {
        const update = {
          id: action.payload.id,
          changes: action.payload,
        };
        connectorsAdapter.updateOne(state, update);
        state.current.data = action.payload;
        state.current.status = 'updated';
      })
      .addCase(updateConnector.rejected, state => {
        state.current.status = 'idle';
      })

      // create single
      .addCase(createConnector.pending, state => {
        state.current.status = 'creating';
      })
      .addCase(createConnector.fulfilled, (state, action) => {
        state.current.data = action.payload;
        state.current.status = 'created';
      })
      .addCase(createConnector.rejected, state => {
        state.current.status = 'idle';
      });
  },
});
/* eslint-enable no-param-reassign */

// Simple actions
export const { setConnector, clearConnector, clearConnectors, clearConnectorsSample } =
  connectorsSlice.actions;

// Selectors
export const { selectAll: selectConnectors, selectById: selectConnectorById } =
  connectorsAdapter.getSelectors(state => state.connectors);

export const selectConnectorIds = createSelector(selectConnectors, connectors =>
  connectors.map(connector => connector.id)
);

export const selectConnectorsMobile = createSelector(selectConnectors, connectors =>
  connectors.filter(connector => connector.supported_devices === 'mobile')
);

export const selectConnectorsDesktop = createSelector(selectConnectors, connectors =>
  connectors.filter(connector => connector.supported_devices === 'desktop')
);

export const selectConnectorsSample = state => state.connectors.sample;
export const selectCurrentConnector = state => state.connectors.current;
export const selectConnectorsMeta = state => state.connectors.meta;
export const selectConnectorsFetchStatus = state => state.connectors.status;

const connectorsReducer = connectorsSlice.reducer;
export default connectorsReducer;
