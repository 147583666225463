const iabCategoryAdapter = c => {
  if (c.id && c.attributes) {
    return {
      id: c.id,
      ...c.attributes,
      relationships: {
        ...c.relationships,
      },
    };
  }
  return {};
};

export default iabCategoryAdapter;
